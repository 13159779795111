import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndustriesTabContent = () => {
    return(
        <>
             <div className="mobileTabContent">
                <Row>
                    <Col style={{padding: 0}}>
                        <div className="gallery">
                            <div className="gallery_scroller">
                                <div>
                                    <div className="clientsCard">
                                        <p>finance/fintech</p>
                                        <h4 className="projectsList">VERANT ID</h4>
                                        <h4 className="projectsList">CRB AUTO</h4>
                                        <h4 className="projectsList">SC VENTURES</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>internet of things</p>
                                        <h4 className="projectsList">AYLA NETWORKS</h4>
                                        <h4 className="projectsList">BEST BUY</h4>
                                        <h4 className="projectsList">DIMPLEX</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>insurance</p>
                                        <h4 className="projectsList">HIROAD</h4>
                                        <h4 className="projectsList">AAA</h4>
                                        <h4 className="projectsList">CSAA</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>education</p>
                                        <h4 className="projectsList">EDUTOPIA</h4>
                                        <h4 className="projectsList">HAL LEONARD</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="desktopTabContent industriesFont">
                <Row className="marginTen">
                    <Col>
                        <p>finance/fintech</p>
                        <h4 className="projectsList">VERANT ID</h4>
                        <h4 className="projectsList">CRB AUTO</h4>
                        <h4 className="projectsList">SC VENTURES</h4>
                    </Col>
                    <Col></Col>
                    <Col>
                        <p>internet of things</p>
                        <h4 className="projectsList">AYLA NETWORKS</h4>
                        <h4 className="projectsList">BEST BUY</h4>
                        <h4 className="projectsList">DIMPLEX</h4>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        <p className="projectsBlurb projectsList" style={{fontSize: 40}}>"we build products that grow your business"</p>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <p>insurance</p>
                        <h4 className="projectsList">HIROAD</h4>
                        <h4 className="projectsList">AAA</h4>
                        <h4 className="projectsList">CSAA</h4>
                    </Col>
                    <Col></Col>
                    <Col>
                        <p>education</p>
                        <h4 className="projectsList">EDUTOPIA</h4>
                        <h4 className="projectsList">HAL LEONARD</h4>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
        </>
    )
}

export default IndustriesTabContent;