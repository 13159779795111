import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AAALogo from "../../../images/OnlineGarage/aaa_logo.png"
import AAAImageGroup from "../../../images/OnlineGarage/aaa_img_group_mobile.png"
import AAAImageGroupDesktop from "../../../images/OnlineGarage/aaa_img_group_desktop.png"

const OnlineGarage = () => {
    return (
        <>
            <Row>
                <Col lg="5" style={{ textAlign: "left" }}>
                    <div className="modalTextLeft aaaScrolling">
                        <h3 className="modalHeader">AAA® ONLINE GARAGE</h3>
                        <div className="crbMobileLogoDiv">
                            <img src={AAALogo} className="aaaLogoMobile" alt="aaa logo" />

                        </div>
                        <p className="modalSubHeader">CHALLENGE</p>
                        <p>
                            AAA needed a way to strengthen the process for consumers to manage and make repair appointments, browse repair shops, connect with customer service, and view claim information.
                        </p>
                        <p className="modalSubHeader">APPROACH</p>
                        <p>
                            Working closely with AAA’s product team, our strategy was predicated on the importance of user experience and scalability. We redesigned the app and introduced micro-services to support multiple vendors and strengthen scalability. We also put an emphasis on performance monitoring and data management to launch an app with multiple partners and integration requirements. 
                        </p>
                        <p className="modalSubHeader">RESULTS</p>
                        <p>
                            Simplified vehicle ownership for consumers, increased business for repair shops, a platform for AAA to provide usage based insurance. The result was a better customer experience, simplified vendor replacement, and enhanced scalability.
                        </p>
                    </div>
                </Col>
                <img
                    src={AAAImageGroup}
                    className="aaaImgGroup"
                    alt="aaa group"
                ></img>
                <Col md="6" className="aaaImagesCol">    
                    <img
                        src={AAAImageGroupDesktop}
                        className="aaaImgGroupDesktop"
                        alt="aaa group"
                    ></img>
                </Col>
            </Row>
        </>
    )
}

export default OnlineGarage;