import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./Navbar.css";
import Logo from "../../images/gradient_br_logo2.svg";
import HamburgerMenu from "../../images/hamburger_menu.svg";

const Nav = (props) => {
    return (
        <>
            <div className="navbar">
                <Row className="navbarRow">
                    <Col>
                        <img src={Logo} style={{ width: 60 }} alt="blue rocket inc logo" />
                    </Col>
                    <button onClick={() => window.location.href='#hero'} className="navBrand">Blue Rocket®</button>
                    <Col style={{ marginTop: 14, position: "absolute", zIndex: -1}} className="mobileNavItems">
                        <button onClick={props.toggleMobileMenu} className="invisibleButton">
                            <img src={HamburgerMenu} style={{height: 20}} alt="hamburger icon"></img>
                        </button>
                    </Col>
                    <Col style={{marginTop: 14, position: "absolute", zIndex: -1}} className="desktopNavItems navbarRow">
                        <a href="#featured" className="navButton">WORK</a>
                        <a href="#meetCrew" className="navButton">ABOUT</a>
                        <a href="#footer" className="navButton">CONTACT</a>
                        <a href="https://www.bluerocket.us/blog/" className="navButton">BLOG</a>
                    </Col>
                </Row>
                
            </div>
        </>
    )
}

export default Nav;