import awProfile from "../../images/MeetCrew/ArielleWeston.svg";
import bhProfile from "../../images/MeetCrew/BethanyHernandez.svg";
import bHiProfile from "../../images/MeetCrew/BrianHill.svg";
import ckProfile from "../../images/MeetCrew/ChadKeller.svg";
import dfProfile from "../../images/MeetCrew/DavidFoote.svg";
import krProfile from "../../images/MeetCrew/KyleRoehrs.svg";
import jtProfile from "../../images/MeetCrew/JessTaylor.svg";
import jwProfile from "../../images/MeetCrew/JonahWeston.svg";
import tsProfile from "../../images/MeetCrew/TaniSanchez.svg";
import lsProfile from "../../images/MeetCrew/LukeSchmidt.svg";
import msProfile from "../../images/MeetCrew/MelissaStayton.svg";
import mmProfile from "../../images/MeetCrew/MichaelMeyer.svg";
import ehProfile from "../../images/MeetCrew/EstevanHernandez.svg";
import gsProfile from "../../images/MeetCrew/GrantSimmons.svg";

export const MeetCrewData = [
  {
    name: "Arielle",
    title: "Product Manager & Designer",
    linkedIn: "https://www.linkedin.com/in/arielle-carr-agilepm/",
    profileImg: awProfile,
    blurb: "Arielle is a veteran project manager, and now leads the design team as well. She began with us as a QA Specialist but quickly moved into a project management role. She likes to take long naps, eat delicious food, and play with her goats and dogs.",
  },
  {
    name: "Bethany",
    title: "Designer",
    linkedIn:
      "https://www.linkedin.com/in/bluerocketcto/",
    profileImg: bhProfile,
    blurb: "Bethany is a very talented UI/UX designer and artist. She enjoys painting and other forms of art. Her favorite genres of book and movie are sci-fi and fantasy. She enjoys cooking, and thinks mushrooms are just really cool.",
  },
  {
    name: "Brian",
    title: "Senior Developer",
    linkedIn: "https://www.linkedin.com/in/brian-hill-2019298/",
    profileImg: bHiProfile,
    blurb: "A senior developer, Brian has enjoyed the variety of projects he’s worked on, continuously adding more languages and platforms to his repertoire. When he’s not working he enjoys playing guitar in a couple bands (one a jazzy funk band and the other a funky jazz band.) His other passion is traveling."
  },
  {
    name: "Chad",
    title: "Senior Software Engineer",
    linkedIn: "https://www.linkedin.com/in/chad-keller-74a4322/",
    profileImg: ckProfile,
    blurb: "As a senior engineer, Chad’s technical leadership fosters the growth of every team member. Some fun facts about him: His favorite movie is So I Married an Axe Murderer. He loves to travel and has visited over 30 different countries. He is particularly fond of pina coladas, and getting caught in the rain."
  },
  {
    name: "David", 
    title: "CEO & CTO",
    linkedIn:
      "https://www.linkedin.com/in/bluerocketcto/",
    profileImg: dfProfile,
    blurb: "With 25 years of experience in the software industry, David has led digital transformation at many large companies, and is especially interested in how people learn and grow in their chosen profession. He is currently writing a book on the evolution of project management. On the weekends he likes to work on the family farm, and his favorite meals are Tacos and Sushi.",
  },
  {
    name: "Estevan",
    title: "Developer",
    linkedIn:
      "https://www.linkedin.com/in/estevan-hernandez-46595b193/",
    profileImg: ehProfile,
    blurb: "Estevan started off in Quality Assurance, but soon moved into Development. He has a passion for learning new technology, creative problem solving, and taking on new challenges. His hobbies include guitar and game development. His favorite book is Dune, and his favorite movie is Stargate.",
  },
  {
    name: "Grant",
    title: "Quality Assurance & Junior Developer",
    linkedIn: 
      "https://www.linkedin.com/in/grantcsimmons/",
    profileImg: gsProfile,
    blurb: "One of the newest additions to the crew, Grant is a diligent worker that pays attention to the finer details in his Quality Assurance position. When he isn't hunting for application bugs, he likes to explore National Parks, try new InstantPot recipies, find projects around the house, or enjoy a chocolate milkshake.",
  },
  {
    name: "Jess",
    title: "Director of Operations",
    linkedIn:
      "https://www.linkedin.com/in/wmjesstaylor/",
    profileImg: jtProfile,
    blurb: "Jess has demonstrated leadership in the mobile industry and consulted in technology for 25 years. A few fun facts: his first career was in seismology, he speaks Italian, and he enjoys studying ancient Greek.",
  },
  {
    name: "Jonah",
    title: "Developer",
    linkedIn:
      "https://www.linkedin.com/in/jonah-weston-a6289a185/",
    profileImg: jwProfile,
    blurb: "A versatile engineer, Jonah started in Quality Assurance and now excels as a developer with expert problem solving ability. He enjoys strategy board games. His favorite movie is Star Wars: Empire Strikes Back. He is fully fluent in StackOverflow and Google.",
  },
  {
    name: "Kyle",
    title: "Developer & Quality Assurance",
    linkedIn:
      "https://www.linkedin.com/in/kyle-roehrs-9a349965/",
    profileImg: krProfile,
    blurb: "Kyle demonstrates great ability to focus and problem solve. He started out as a Quality Assurance specialist and very quickly moved into iOS and Web Development. His hobbies include: playing golf, basketball, and shooting video. His favorite movies are The Social Network, Inception, and 12 Angry Men.",
  },
  {
    name: "Luke",
    title: "Quality Assurance & Junior Developer",
    linkedIn:
      "https://www.linkedin.com/in/luke-schmidt-720a551b8/",
    profileImg: lsProfile,
    blurb: "Another newcomer is Luke, a talented and very detail oriented Quality Assurance Engineer and Junior Web Developer who shows a great passion for learning. His hobbies include: hiking, biking, traveling, PC gaming, and reading. His favorite movie is Interstellar. (Good choice, Luke.)",
  },
  {
    name: "Melissa",
    title: "Project Manager",
    linkedIn:
      "https://www.linkedin.com/in/staytonmc/",
    profileImg: msProfile,
    blurb: "As a skilled and thoughtful veteran Project Manager, Melissa is passionate about taking care of her team and helping them maintain a state of “flow.” Some fun facts about her: she is a fraternal twin and was a competitive figure skater growing up. Her favorite movies are the Fifth Element and Wall-E. Her favorite meal is shrimp wonton ramen. Her hobbies include learning new tech and art skills, listening to french language podcasts, and succulent gardening.",
  },
  {
    name: "Michael",
    title: "CFO",
    linkedIn:
      "https://www.linkedin.com/in/michaelmeyer50/",
    profileImg: mmProfile,
    blurb: "Michael keeps the finances in order for Blue Rocket. Some fun facts about him: He loves science fiction and fantasy. He also is a soccer fan. He has lived and worked in Canada, Australia, and Korea and is now going to help his family start a craft brewery and a farm in Kentucky.",
  },
  {
    name: "Tani",
    title: "Developer & Designer",
    linkedIn:
      "https://www.linkedin.com/in/tani-sanchez-b8b48660/",
    profileImg: tsProfile,
    blurb: "Tani started off in Quality Assurance before making her way into Development and Design. She loves to learn and acquire new skills. Her hobbies include: guitar, singing, art, reading, writing, and podcasting with her sister. Before joining the team she was a photographer and video editor, and still does the occasional photo shoot. She is also on teams Interstellar and Dune.",
  },
];
