import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HiRoadLogo from "../../../images/HiRoad/HiRoadLogo.svg";
import FullScreen from "../../../images/HiRoad/hi_road_fullscreen.png";
import SmallScreen from "../../../images/HiRoad/hi_road_smallscreen.png"

const HiRoad = () => {
    return (
        <>
            <Row>
                <Col lg="5" style={{ textAlign: "left" }}>
                    <div className="modalTextLeft hiRoadScrolling">
                        <h3 className="modalHeader">HIROAD</h3>
                        <div className="hiRoadMobileLogo">
                            <img 
                                style={{
                                    maxWidth: "15rem"
                                }}
                                src={HiRoadLogo} 
                                alt="hiroad logo"
                            />
                        </div>
                        <p className="modalSubHeader">CHALLENGE</p>
                        <p>
                            Most auto insurance companies focus on the negatives when it comes to working with consumers. HiRoad aimed to change all that by rewarding good driving habits on the road. 
                        </p>
                        <p>
                            Consumer expectations and behavior are shifting rapidly. They demand more personalization and convenience than ever before. They tasked us with building an app that drivers can take with them while driving, providing key telematics on behavior.
                        </p>
                        <p className="modalSubHeader">APPROACH</p>
                        <p>
                            Insurance has been thrust onto the innovation stage as new technology shapes the future. Connected cars and usage based insurance are changing how insurers compete for customers, rate their risks, and save lives. 
                        </p>
                        <p>
                            This meant developing a native mobile app with a unique approach to providing drivers with helpful driving info. Our team has been integral in launching and maintaining the mobile app, from design and architecture to testing and release management.
                        </p>
                        <p className="modalSubHeader">RESULTS</p>
                        <p>
                            While we can’t get into all the details, through a unique partnership and cross-team collaboration, we built an end to end framework with test-driven development that helped streamline and automate building a world-class application. We advocated for a native solution and integrated testing to create a complex app with few errors that incentivized good driving behavior.
                        </p>
                    </div>
                </Col>
                <Col md="6">
                    <div className="hiRoadGraphics">
                        <div className="hiRoadCircle"
                        ></div>
                        <img 
                            src={FullScreen}
                            className="hiRoadPhone"
                            alt="hiroad phone screenshot"
                        ></img>
                        <img 
                            src={SmallScreen}
                            className="hiRoadSmallScreen"
                            alt="hiroad screenshot small"
                        ></img>
                        <img 
                            src={HiRoadLogo}
                            className="hiRoadDesktopLogo"
                            alt="hiroad logo"
                        ></img>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default HiRoad;