import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Exit from "../../images/exit.svg"

const MobileNav = (props) => {
    return(
        <div id="myMobileNav" style={{height: props.navHeight}} className="mobileNavOverlay">
            <a className="closebtn" onClick={() => {props.toggleMobileMenu()}}>
                <img src={Exit} className="mobileNavExit" alt="exit button" />
            </a>
            <div className="mobile-overlay-content">
                <Row>
                    <Col style={{marginBottom: 30}}>
                        <a 
                            onClick={() => {props.toggleMobileMenu()}} 
                            href="#featured" 
                            className="mobileNavButton"
                        >
                                WORK
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 30}}>
                        <a 
                            onClick={() => {props.toggleMobileMenu()}}
                            href="#meetCrew" 
                            className="mobileNavButton"
                        >
                            ABOUT
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 30}}>
                        <a 
                            onClick={() => {props.toggleMobileMenu()}}
                            href="#footer" 
                            className="mobileNavButton"
                        >
                            CONTACT
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a 
                            onClick={() => {props.toggleMobileMenu()}}
                            href="https://bluerocket.us/blog/" 
                            className="mobileNavButton"
                        >
                            BLOG
                        </a>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MobileNav;