import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./MeetCrew.css"
import { MeetCrewData } from "./MeetCrewData";

const MeetCrew = (props) => {

    return (
        <div onMouseEnter={() => props.setDiv("meetCrewDiv")}>
            <a name="meetCrew"></a>
            <div className="meetCrewBanner">
                <Row>
                    <Col>
                        <h2 style={{textAlign: "right", marginBottom: "5rem"}}>MEET THE <h2 style={{color: "#EF766E", display: "inline"}}>CREW</h2></h2>
                    </Col>
                </Row>

                <div className="mobileDiv">
                    <Row>
                        <Col style={{textAlign: "center"}}>
                            {
                                MeetCrewData.map((element, i) => {
                                    return (
                                        <>
                                            <img onClick={() => props.openProfileDetails(element)} src={element.profileImg} className="meetCrewImg" alt={`profile drawing of ${element.name}`} />
                                        </>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                            
                <div className="desktopDiv">
                    <Row>
                        {
                            MeetCrewData.map((element, i) => {
                                return (
                                    <>
                                        <Col md="4" style={{textAlign: "center", marginBottom: "5rem", padding: 50}}>
                                            <img src={element.profileImg} style={{height: "10rem", marginBottom: "1rem"}} alt={`profile drawing of ${element.name}`} />
                                            <h6>{element.name}</h6>
                                            <p>{element.title}</p>
                                            {element.blurb !== "" ?
                                                <p className="profileBlurb">{element.blurb}</p> :
                                                <p className="profileBlurb">Here is a blurb about the person and maybe some fun fact or hobbies they do idk.</p>
                                            }
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>  
                </div>
            </div>
        </div>
    )
}

export default MeetCrew;