import React from "react"
import "./Featured.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FeaturedSummaryData } from "./FeaturedSummaryData";
import FeaturedSummary from "./FeaturedSummary";
import FeaturedMobileCarousel from "./FeaturedMobileCarousel";

const Featured = (props) => {

    const selectFeature = (option) => {
        props.setSelectedFeature(option)
    }

    return (
        <div onMouseOver={() => props.setDiv("featuredDiv")}>
            <a name="featured"></a>
            <div className="featuredBanner">
                <Row  style={{marginBottom: 30}}>
                    <Col>
                        <h2 className="featuredProjectsText">FEATURED PROJECTS</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <p  className="featuredProjectsText" style={{marginBottom: 30}}>Check out our favorite projects!</p>
                        <div className="mobileFeaturedCarouselDiv">
                            <FeaturedMobileCarousel setSelectedFeature={props.setSelectedFeature} />
                        </div>                        
                        <div className="desktopFeaturedCarouselDiv">
                            <ul className="featuredList">
                                {FeaturedSummaryData.map((element, i) => {
                                    return(
                                        <button 

                                            onClick={() => {selectFeature(element.key)}} 
                                            className={props.selectedFeature === element.key ? `featuredListName selectedFeature invisibleButton` : `featuredListName invisibleButton`}
                                            key={i}
                                        >
                                            {element.name}
                                        </button>
                                    )
                                })}
                            </ul>
                        </div>      
                    </Col>
                    <Col>
                        <div className="featuredSummaryBox">
                           <FeaturedSummary 
                                openFeatureModal={props.openFeatureModal} 
                                summaryKey={props.selectedFeature}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Featured;