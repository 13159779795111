import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PurpleBlob from "../../../images/Letsbloom/purple_blob.svg"
import OrangeBlob from "../../../images/Letsbloom/orange_blob.svg"
import ThreeDImg from "../../../images/Letsbloom/3d_image.png"
import LetsbloomLogo from "../../../images/Letsbloom/letsbloom_logo.svg"
import LetsbloomGroupImg from "../../../images/Letsbloom/letsbloom_group_img.png"

const Letsbloom = () => {
    return (
        <>
            <Row>
                <Col lg="5" style={{ textAlign: "left" }}>
                    <div className="modalTextLeft letsbloomScrolling">
                        <h3 className="modalHeader">LETSBLOOM</h3>
                        <div className="crbMobileLogoDiv">
                            <img src={LetsbloomLogo } className="crbMobileLogo" alt="letsbloom logo" />
                        </div>
                        <img src={LetsbloomGroupImg} className="letsbloomGroupImg" alt="letsbloom group for mobile"></img>
                        <p className="modalSubHeader">CHALLENGE</p>
                        <p>
                            Building a trusted financial app that meets bank-grade compliance and security requirements is not for the faint of heart. We were tasked with designing and building a platform to help companies build next generation apps with security and compliance in mind.
                        </p>
                        <p className="modalSubHeader">APPROACH</p>
                        <p>
                            Our team worked closely with letsbloom to create a platform built on security and compliance that helped bootstrap companies to be secure-by-design and compliant-by-default.
                        </p>
                        <p className="modalSubHeader">RESULTS</p>
                        <p>
                            The letsbloom website leverages a trusted infrastructure to offer a unique approach to build, deploy and manage secure, compliant applications on the cloud. For companies, it provides continuous in-depth reporting of insights and clear actions to help manage their application’s security and compliance posture.
                        </p>
                    </div>
                </Col>
                <Col md="6">
                    <div
                        className="letsbloomGroupSet"
                    >
                        <img 
                            className="purpleBlob bounce-2"
                            src={PurpleBlob} 
                            alt="purple blob with hover animation"
                        />
                        <img 
                            src={OrangeBlob} 
                            className="orangeBlob bounce-2"
                            alt="orange blob with hover animation"
                        />
                        <img 
                            src={ThreeDImg} 
                            className="letsbloom3D"
                            alt="letsbloom 3d abstract rendering"
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Letsbloom;