import React from "react"
import "./Capabilities.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TeamImg from "../../images/team_huddle.svg";

const Capabilities = (props) => {
    return (
        <div onMouseOver={() => props.setDiv("capabilitiesDiv")}>
            <a name="capabilities"></a>
            <div className="capabilitiesBanner">
                <Row>
                    <Col style={{padding: 0, marginRight: 30}} lg="5">
                        <div>
                            <img src={TeamImg} style={{width: "100%", marginTop: "10%"}} alt="team line drawing" />
                        </div>
                    </Col>
                    <Col>
                        <h2 className="capabilitiesHeader" style={{letterSpacing: 2}}>CAPABILITIES</h2>
                        <Row>
                            <Col style={{padding: 0}} md="6">
                                    <h6>
                                        PRODUCT STRATEGY
                                    </h6>
                                    <ul>
                                        <li>
                                            Product Definition Workshops
                                        </li>
                                        <li>
                                            Product Roadmap
                                        </li>
                                        <li>
                                            User Research
                                        </li>
                                    </ul>
                            </Col>
                            <Col>
                                <h6>
                                    DESIGN SERVICES
                                </h6>
                                <ul>
                                    <li>
                                        User Experience Design
                                    </li>
                                    <li>
                                        Visual Design
                                    </li>
                                    <li>
                                        Rapid Prototyping
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <h6>
                                    TECHNOLOGY
                                </h6>
                                <ul>
                                    <li>
                                        Native mobile development (iOS & Android)
                                    </li>
                                    <li>
                                        Responsive Web Development
                                    </li>
                                    <li>
                                        Server-Side Development & Third-Party Integrations
                                    </li>
                                    <li>
                                        End-to-End System Architecture Design and Development
                                    </li>
                                    <li>
                                        Quality Assurance Services
                                    </li>
                                </ul>  
                            </Col>
                            <Col md="6">
                                <h6>
                                    LAUNCH AND POST-LAUNCH SERVICES
                                </h6>
                                <ul>
                                    <li>
                                        Pre-Launch Testing (alpha & beta)
                                    </li>
                                    <li>
                                        Launch & Release Planning and Implementation
                                    </li>
                                    <li>
                                        Post-Launch Support & Proactive Intervention
                                    </li>
                                    <li>
                                        Ongoing Support & Enhancements
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <h6>
                                    METHODOLOGY
                                </h6>
                                <ul>
                                    <li>
                                        Employs proven product delivery methodology named Quantum Agile℠
                                    </li>
                                </ul>
                            </Col>
                        </Row>                       
                        <Row style={{paddingTop: "2rem"}}>
                            <Col className="aboutUsBtnCol">
                                <a href="#meetCrew" className="whiteOutlineBtn purpleOutlineBtn"> ABOUT US</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Capabilities;