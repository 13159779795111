export const FeaturedSummaryData = [
  {
    name: "BEST BUY",
    summary: "Produced Best Buy’s first IoT app for iOS and Android. The highly-rated app synthesized design and technology for a seamless experience across the physical and digital interfaces.",
    key: 0,
  },
  {
    name: "HIROAD",
    summary: "Through a unique partnership and cross-team collaboration, we built an end-to-end framework with test-driven development that helped streamline and automate building a world-class application.",
    key: 1,
  },
  {
    name: "CRB AUTO",
    summary: "Designed and built a user experience that focused on ease of use and real time notifications. The CRB Auto app now supports thousands of users to manage and make payments on their loan.",
    key: 2,
  },
  {
    name: "LETSBLOOM",
    summary: "Created a platform built on security and compliance that helps bootstrap companies to be secure-by-design and compliant-by-default.",
    key: 3,
  },
  {
    name: "AAA ONLINE GARAGE",
    summary: "Produced native and responsive web apps, and introduced micro-services to support multiple vendors and strengthen scalability. The result was a better customer experience, simplified vendor replacement, and enhanced scalability.",
    key: 4,
  }
];
