import React, {useState} from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Projects.css"
import ServicesTabContent from "./ServicesTabContent";
import IndustriesTabContent from "./IndustriesTabContent";

const Projects = (props) => {
    const [selectedTab, setSelectedTab] = useState("services");

    const chooseTab = (tab) => {
        setSelectedTab(tab);
    }
    
    return (
        <div onMouseOver={() => props.setDiv("projectsDiv")}>
            <a name="clients"></a>
            <Row className="clientsHeaderRow" style={{marginBottom: 30}}>
                <Col>
                    <h2>CLIENTS</h2>
                </Col>
            </Row>

            <Row className="projectsRow" style={{marginBottom: 40}}>
                <Col md="6">
                    <Row className="projectsSelectorRow">
                        <Col 
                            onClick={() => chooseTab("services")}
                            className={selectedTab === "services" ? `selected servicesTab slideLeft` : `notSelected`} 
                            style={{textAlign: "center"}}
                        >
                            <p style={{margin: 10}}>services</p>
                        </Col>
                        <Col 
                            onClick={() => chooseTab("industries")} 
                            className={selectedTab === "industries" ? `selected slideRight` : `notSelected`} 
                            style={{textAlign: "center"}}
                        >
                            <p style={{margin: 10}}>industries</p>
                        </Col>
                        {/* <Col 
                            onClick={() => chooseTab("all")}
                            style={{textAlign: "center"}}
                            className={selectedTab === "all" ? `selected` : ``}
                        >
                            <p style={{margin: 10}}>view all</p>
                        </Col> */}
                    </Row>
                </Col>
                <Col></Col>
            </Row>

            {selectedTab === "services" &&
                <ServicesTabContent />
            }
            {selectedTab === "industries" &&
                <IndustriesTabContent />
            }
            {/* {selectedTab === "all" &&
                <AllTabContent />
            } */}
        </div>
    )
}

export default Projects;