import React from "react"
import { FeaturedSummaryData } from "./FeaturedSummaryData";

const FeaturedSummary = (props) => {
    return(
        <>
            {FeaturedSummaryData
                .filter((element) => {
                    if (element.key === props.summaryKey) {
                    return true;
                    } else {
                    return false;
                    }
                })
                .map((element, i) => (
                    <div key={i + `project`}>
                        <div style={{ padding: 30 }}>
                            <p>
                                {element.summary}
                            </p>
                        </div>
                        <div className="learnMoreDiv">
                            <button 
                                className="whiteOutlineBtn purpleOutlineBtn" 
                                onClick={e => {props.openFeatureModal(element)}}
                            >
                                    LEARN MORE
                            </button>
                        </div>
                    </div>
                )
            )}        
        </>
    )
}

export default FeaturedSummary;