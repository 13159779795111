import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./FunFacts.css";
import Planetoid from "../../images/planetoid.svg";
import PlanetoidRight from "../../images/planetoid_right.svg";

const FunFacts = (props) => {
    return (
        <div onMouseOver={() => props.setDiv("funFactsDiv")}>
            <a name="funFacts"></a>
            <img className="planetoidRight planetoidFloat bounce-2" src={PlanetoidRight} alt="planetoid right" />
            <div className="funFactsBanner">
                <h1 className="innovationHeader">WE MAKE INNOVATION WORK</h1>
                <Row style={{margin: "0 auto"}}>
                    <Col style={{padding: "0 5% 0 0"}} md="6" className="funFactsCol">
                        <h5 className="funFactsHeader">FUN FACTS</h5>
                        <ul className="funFactsUl">
                            <li className="funFactsLi">
                                Blue Rocket employees live in seven of the United States, because our employees worked at home before it was cool
                            </li>
                            <li className="funFactsLi">
                                27.3% percent of the crew thinks Interstellar is the best sci-fi movie, and 72.7% are wrong
                            </li>
                            <li className="funFactsLi">
                                Serving clients since 2008
                            </li>
                            <li className="funFactsLi">
                                Possess a combined 110 total years of experience in the technology industry 
                            </li>
                            <li className="funFactsLi">
                                Over 150 projects completed
                            </li>
                        </ul>
                    </Col>
                    <Col style={{padding: "0 0 0 5%"}} className="firstsCol">
                        <h5 className="funFactsHeader">HISTORY OF FIRSTS</h5>
                        <ul className="funFactsUl">
                            <li className="funFactsLi">
                                2009 – Launched Chipotle’s initial native iOS mobile ordering app lauded to be the first truly successful and wide-reaching e-commerce app on the Apple app store.
                            </li>
                            <li className="funFactsLi">
                                2010 – Apple asked Zinio, a digital magazine provider with over 2000 titles, to provide a new app that would launch installed on the first iPads ever produced. Blue Rocket enabled Zinio to do this in just six weeks.
                            </li>
                            <li className="funFactsLi">
                               2011 – Produced for Standard Chartered Bank an alternative to paper board meeting packages via a secure, digital transport and reading experience on the iPad, named <a target="_blank" href="http://www.boardpapers.com/" style={{color: "#2DBC9D"}}>Board Papers</a>, that Apple showcased on iPads in Apple stores.
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
            {/* <Row>
                <Col> */}
                    <img src={Planetoid} className="planetoid planetoidFloat bounce-2" alt="planetoid" />
                {/* </Col>
            </Row> */}
        </div>
    )
}

export default FunFacts;