import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Exit from "../../images/exit.svg"

const MeetCrewMobileDetails = (props) => {
    const profile = props.profile;

    return(
        <div id="myMobileNav" style={{height: props.profileDetailsHeight}} className="mobileNavOverlay">
            <a className="closebtn" onClick={() => {props.closeProfileDetails()}}>
                <img src={Exit} className="mobileNavExit" alt="exit button" />
            </a>
            <div style={{top: "5%"}} className="mobile-overlay-content">
                <Row>
                    <Col style={{marginBottom: 30}}>
                        <h2 style={{textTransform: "uppercase"}}>{profile.name}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginBottom: 30}}>
                        <p style={{fontSize: 24}}>{profile.title}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={{margin: "0 15%"}}>{profile.blurb}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MeetCrewMobileDetails;