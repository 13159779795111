import React, { useEffect } from "react"
import "./FeaturedModal.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FeaturedSummaryData } from "../FeaturedSummaryData";
import BestBuyDetails from "./BestBuy";
import HiRoad from "./HiRoad";
import CrbAuto from "./CrbAuto";
import Letsbloom from "./Letsbloom"
import OnlineGarage from "./OnlineGarage"
import UpArrow from "../../../images/up_arrow.svg"
import DownArrow from "../../../images/down_arrow.svg"
import Exit from "../../../images/exit.svg"
import GreenWave from "../../../images/HiRoad/green_wave.svg"
import GrayWave from "../../../images/CrbAuto/gray_wave.svg"
import LeftRedBars from "../../../images/CrbAuto/left_red_bars.svg"
import RightRedBars from "../../../images/CrbAuto/right_red_bars.svg"
import RoadWave from "../../../images/OnlineGarage/road_wave.png"

const FeaturedDetailsModal = (props) => {
    const handleClick = (direction) => {
        props.setActiveIndex((prevIndex) => {
            if (direction === "next") {
                if (prevIndex + 1 > FeaturedSummaryData.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }
            if (prevIndex - 1 < 0) {                
                return FeaturedSummaryData.length - 1;
            }
            return prevIndex - 1;
        });
    };

    useEffect(() => {
        const setProjectKey = async () => {
            props.setActiveIndex(props.featuredProject.key)
        };

        (async function getProject() {
            if (props.featuredProject) {
                await setProjectKey();
            }
        })();
    }, [props.featuredProject]);

    return (
        <>
            <div 
                id="myNav" 
                style={{height: props.height}} 
                className={
                    `overlay overlay${props.activeIndex}`
                }
            >
                <Row style={{ position: "sticky", top: 0, zIndex: 15}}>
                    <Col>
                        <a onClick={() => handleClick('prev')} className="scrollUp">
                            <img src={UpArrow} style={{height: 30, marginTop:  15}} alt="up arrow"/>
                        </a>
                    </Col>
                    <Col>
                        <a className="closebtn" onClick={() => {props.closeFeatureModal()}}>
                            <img src={Exit} style={{height: 20}} alt="exit icon" />
                        </a>
                    </Col>
                </Row>
                <Row style={{position: "sticky", bottom: 0, top: "91%", height: 0}}>
                    <Col>
                        <a onClick={() => handleClick('next')} className="scrollDown">
                            <img src={DownArrow} style={{height: 30}} alt="down arrow" />
                        </a>
                    </Col>
                </Row>
                {props.activeIndex === 2 &&
                    <img 
                        src={LeftRedBars}
                        className="redLeftBars"
                        alt="left red bars with animation"
                    />
                }
                <div className="overlay-content">
                    {props.activeIndex === 0 &&
                        <BestBuyDetails />
                    }
                    {props.activeIndex === 1 &&
                        <HiRoad />
                    }
                    {props.activeIndex === 2 &&
                        <CrbAuto />
                    }
                    {props.activeIndex === 3 &&
                        <Letsbloom />
                    }
                    {props.activeIndex === 4 &&
                        <OnlineGarage />
                    }
                </div>
                {props.activeIndex === 1 &&
                    <img 
                        src={GreenWave} 
                        style={{ display: props.display}}
                        className="greenWave"
                        alt="hiroad green wave"
                    />
                }
                {props.activeIndex === 4 &&
                    <img 
                        src={RoadWave} 
                        style={{ display: props.display }}
                        className="roadWave"
                        alt="online garage aaa road wave"
                    />
                }
                {props.activeIndex === 2 &&
                    <>
                        <img 
                            src={GrayWave} 
                            style={{ display: props.display }}
                            className="grayWave"
                            alt="crb auto gray wave"
                        />
                        <img 
                            src={RightRedBars}
                            style={{ display: props.display }}
                            className="redBars"
                            alt="right red bars with animation"
                        />                        
                    </>
                }
            </div>
        </>
    )
}

export default FeaturedDetailsModal;