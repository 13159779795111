import React from "react"
import "./HeroBanner.css"
import Astronaut from "../../images/astronaut.png"
import AstroRight from "../../images/astronaut_right.png"
import Planetoid from "../../images/planetoid.svg";

const HeroBanner = (props) => {
    return (
        <div onMouseOver={() => props.setDiv("heroDiv")}>  
            <a name="hero" style={{position: "absolute", top: 0}}></a>
            <img src={AstroRight} className="astronautRight" alt="astronaut" />
            <img src={Astronaut} className={`astronaut ${props.div}`} alt="astronaut" />
            <div className="heroBanner">
                <h1 style={{marginBottom: "2rem"}}>BUILDING WHAT'S NEXT</h1>
                <h4 className="heroBlurb" style={{marginBottom: "3rem" }}>Blue Rocket, a global digital product design agency, applies proven methodology and an eye for the unknown to lift your ideas off the ground.</h4>
                <a href="#featured" style={{width: "100%", maxWidth: "20rem"}} className="whiteOutlineBtn">EXPLORE THE WORK {`>`} </a>
            </div>
            <img src={Planetoid} className="planetoidOne" alt="planetoid" />
        </div>
    )
}

export default HeroBanner;