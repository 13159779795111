import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BestBuyLogo from "../../../images/BestBuy/best_buy_logo.svg"
import PhoneOne from "../../../images/BestBuy/bestbuy1.png";
import PhoneTwo from "../../../images/BestBuy/bestbuy2.png";
import PhoneThree from "../../../images/BestBuy/bestbuy3.png";
import YellowSquare from "../../../images/BestBuy/yellow_square.svg";
import YellowSquare3 from "../../../images/BestBuy/yellow_square_3.svg";

const BestBuy = () => {
    return (
        <>
            <Row>
                <Col lg="5" style={{ textAlign: "left" }}>
                    <div className="modalTextLeft bestBuyScrolling">
                        <h3 className="modalHeader bestBuyHeader">BEST BUY</h3>
                        <div className="bestBuyLogoHeader">
                            <img style={{ maxWidth: "15rem"}} src={BestBuyLogo} alt="best buy logo"/>
                        </div>
                        <p className="modalSubHeader">CHALLENGE</p>
                        <p>
                            As a longtime player in the retail tech industry, Best Buy sought to push the IoT field with their first connected mobile app.  From smart plugs to connected freezers, the mobile app merged the digital with cutting edge technology to reflect the new value proposition of Best Buy.
                        </p>
                        <p className="modalSubHeader">APPROACH</p>
                        <p>
                            Because Best Buy technology leveraged a new IoT platform, we approached their app with a focus on ease of use, creating a design system and platform that fused disparate features into a cohesive experience, while merging our IoT expertise and best-in-class agile development for lightning-fast delivery.
                        </p>
                        <p className="modalSubHeader">RESULTS</p>
                        <p>
                            We conducted an experience-focused workshop with the Best Buy team to test out various product design ideas. We then produced Best Buy’s first IoT app for iOS and Android. The highly-rated app synthesized both the design and technology for a seamless experience across the physical and digital experiences.
                        </p>
                    </div>
                </Col>
                <Col lg="6">
                    <Row className="bestBuyLogoRow">
                        <Col>
                            <img src={BestBuyLogo} style={{width: "15rem", marginBottom: 40}} alt="best buy logo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6" style={{padding: 0}}>
                            <img 
                                src={YellowSquare} 
                                className="yellowSquare1 bounce-2"
                                alt="yellow square"
                            />
                            <img 
                                src={PhoneOne} 
                                className="bestBuyPhone1"
                                alt="best buy app phone example 1"
                            />
                        </Col>
                        <Col sm="6">
                            <img 
                                src={PhoneTwo} 
                                className="bestBuyPhone2"
                                alt="best buy app phone example 2"
                            />

                            <img 
                                className="yellowSquare4 bounce-2"
                                src={YellowSquare3} 
                                alt="yellow square"
                            />
                        </Col>
                        <Col>
                            <img src={PhoneThree} className="bestBuyPhone3" alt="best buy app phone example 3"></img>
                            <img 
                                className="yellowSquare5 bounce-2"
                                src={YellowSquare} 
                                alt="yellow square"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default BestBuy;