import React, {useEffect, useState} from "react"
import HeroBanner from "./HeroBanner/HeroBanner"
import Featured from "./Featured/Featured"
import Capabilities from "./Capabilities/Capabilities"
import Projects from "./Projects/Projects"
import FunFacts from "./FunFacts/FunFacts"
import MeetCrew from "./MeetCrew/MeetCrew"
import Footer from "./Footer/Footer"
import Nav from "./Navbar/Nav"
import MobileNav from "./Navbar/MobileNav"
import FeaturedDetailsModal from "./Featured/FeaturedDetails/FeaturedDetailsModal"
import UpButton from "../images/up_button.svg"
import "./TopLevel.css";
import MeetCrewMobileDetails from "./MeetCrew/MeetCrewMobileDetails"

const TopLevel = () => {
    
    const [showFeaturedModal, setShowFeaturedModal] = useState(false);
    const [featuredProject, setFeaturedProject] = useState();
    const [height, setHeight] = useState("0%");
    const [projectName, setProjectName] = useState();
    const [selectedFeature, setSelectedFeature] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [navHeight, setNavHeight] = useState("0%");
    const [display, setDisplay] = useState("");
    const [displayMobileNav, setDisplayMobileNav] = useState(false);
    const [displayProfileDetails, setDisplayProfileDetails] = useState(false);
    const [profile, setProfile] = useState({});
    const [profileDetailsHeight, setProfileDetailsHeight] = useState("0%")
    const [div, setDiv] = useState("heroDiv");

    // scroll to top button functionality
    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {    
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)    
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)    
        }  
    };
    window.addEventListener('scroll', checkScrollTop)
    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
    
    // mobile navigation functionality
    const toggleMobileMenu = () => {
        if (displayMobileNav) {
            setNavHeight("0%");
            setDisplayMobileNav(false);
        } else {
            setNavHeight("100%");
            setDisplayMobileNav(true);
        }
    };
    const openNav = () => {
        setNavHeight("100%");
    }
    const closeNav = () => {
        setNavHeight("0%");
    }

    // featured projects modal functionality
    const openFeatureModal = (project) => {
        setHeight("100%");
        setDisplay("block")
        setFeaturedProject(project)
        setProjectName(project.name);
    }
    
    function closeFeatureModal() {
        setHeight("0%");
        setDisplay("none")

        // give it a second to reset the key so you don't see it on close
        setTimeout(function() { 
            setActiveIndex(selectedFeature)
        }(this), 1000)
    }

    // profile details functionality
    const openProfileDetails = (profile) => {
        setProfile({...profile})
        setProfileDetailsHeight("100%")
    }
    const closeProfileDetails = () => {
        setProfileDetailsHeight("0%")
        setDisplayProfileDetails(true);
    }


    return (
        <>
            <FeaturedDetailsModal 
                showFeaturedModal={showFeaturedModal} 
                setShowFeaturedModal={setShowFeaturedModal}
                featuredProject={featuredProject}
                setFeaturedProject={setFeaturedProject}
                projectName={projectName}
                openFeatureModal={openFeatureModal}
                closeFeatureModal={closeFeatureModal}
                height={height}
                setHeight={setHeight}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                display={display}
            />
            <MobileNav 
                openNav={openNav} 
                closeNav={closeNav}
                navHeight={navHeight}
                setNavHeight={setNavHeight}
                toggleMobileMenu={toggleMobileMenu}
                displayMobileNav={displayMobileNav}
            />
            <MeetCrewMobileDetails 
                openProfileDetails={openProfileDetails}
                closeProfileDetails={closeProfileDetails}
                profileDetailsHeight={profileDetailsHeight}
                setProfileDetailsHeight={setProfileDetailsHeight}
                profile={profile}
            />
            <Nav 
                toggleMobileMenu={toggleMobileMenu} 
            />
            <HeroBanner div={div} setDiv={setDiv}/>
            <Featured 
                openFeatureModal={openFeatureModal}
                closeFeatureModal={closeFeatureModal}
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
                setDiv={setDiv}
            />
            <Capabilities 
                div={div}
                setDiv={setDiv} 
            />
            <Projects 
                div={div}
                setDiv={setDiv} 
            />
            <FunFacts 
                div={div}
                setDiv={setDiv} 
            />
            <MeetCrew 
                div={div}
                setDiv={setDiv} 
                openProfileDetails={openProfileDetails}
                closeProfileDetails={closeProfileDetails}
                // toggleProfileDetails={toggleProfileDetails}
            />
            <Footer 
                div={div}
                setDiv={setDiv} 
            />
            <button
                className="upButton" 
                style={{ display: showScroll ? 'flex' : 'none' }}
                onClick={scrollTop}
            >
                <img src={UpButton} style={{ height: 50, width: 50 }} alt="to top button"></img>
            </button>
            
        </>
    )
}

export default TopLevel;