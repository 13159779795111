import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Screenshots from "../../../images/CrbAuto/crb_auto_screenshots.png"
import CrbLogo from  "../../../images/CrbAuto/crb_logo.png"

const CrbAuto = (props) => {
    return (
        <>
           <Row>
                <Col lg="5" style={{ textAlign: "left" }}>
                    <div className="modalTextLeft crbScrolling">
                        <h3 className="modalHeader">CALIFORNIA REPUBLIC BANK</h3>
                        <div className="crbMobileLogoDiv">
                            <img src={CrbLogo} className="crbMobileLogo" alt="crb logo" />
                        </div>
                        <p className="modalSubHeader">CHALLENGE</p>
                        <p>
                            California Republic Bank (CRB Auto), a division of Mechanics Bank, needed a mobile app to strengthen how it captures new auto loan business. The app needed to enhance customer service, increase engagement, and improve how consumers make payments on mobile.
                        </p>
                        <p className="modalSubHeader">APPROACH</p>
                        <p>
                            Our strategy was to design and build a user experience that focused on ease of use and real time notifications. We introduced alerts that reminded borrowers of important payment dates and created opportunities for additional customer engagement.
                        </p>
                        <p className="modalSubHeader">RESULTS</p>
                        <p>
                            With a keen eye on regulatory compliance and back-end integration of the app, the CRB Auto app now supports thousands of users to manage and make payments on their loan. The app leverages QR codes to facilitate one-step user onboarding and Touch/Face ID to ease payment authentication.
                        </p>
                    </div>
                </Col>
                <Col lg="6">
                    <div style={{textAlign: "center"}}>
                        <img 
                            src={CrbLogo}
                            className="crbDesktopLogo"
                            alt="crb logo"
                        ></img>
                    </div>
                    <div className="crbScreenshotsDiv">
                        <img 
                            src={Screenshots}
                            className="crbScreenshotsImg"
                            alt="crb app screenshots"
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CrbAuto;