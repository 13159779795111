import React, {useEffect, useState} from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ArrowRight from "../../images/arrow_right_turquoise.svg"
import ArrowLeft from "../../images/arrow_left_turquoise.svg"
import { FeaturedSummaryData } from "./FeaturedSummaryData";

const FeaturedMobileCarousel = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (direction) => {
        setActiveIndex((prevIndex) => {
            if (direction === "next") {
                if (prevIndex + 1 > FeaturedSummaryData.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }
            if (prevIndex - 1 < 0) {                
                return FeaturedSummaryData.length - 1;
            }
            return prevIndex - 1;
        });
    };

    useEffect(() => {
        props.setSelectedFeature(activeIndex)
    }, [activeIndex])

    return (
        <>
            <Row>
                <Col style={{textAlign: "center"}}>
                    <button className="invisibleButton arrowLeftTurqBtn" onClick={() => handleClick('prev')}>
                        <img src={ArrowLeft} className="arrowLeftTurquoise" alt="arrow left" />
                    </button>
                    

                    {activeIndex === 0 &&
                        <button className="featuredMobileCarouselName" onClick={() => {props.selectFeature(activeIndex)}} >
                            BEST BUY
                        </button>
                    }
                    {activeIndex === 1 &&
                        <button className="featuredMobileCarouselName" onClick={() => {props.selectFeature(activeIndex)}}>
                            HIROAD
                        </button>
                    }
                    {activeIndex === 2 &&
                         <button className="featuredMobileCarouselName" onClick={() => {props.selectFeature(activeIndex)}}>
                            CRB AUTO
                        </button>
                    }
                    {activeIndex === 3 &&
                         <button className="featuredMobileCarouselName" onClick={() => {props.selectFeature(activeIndex)}}>
                            LETSBLOOM
                        </button>
                    }
                    {activeIndex === 4 &&
                        <button className="featuredMobileCarouselName" onClick={() => {props.selectFeature(activeIndex)}}>
                            AAA ONLINE GARAGE
                        </button>
                    }

                    <button onClick={() => handleClick('next')} className="invisibleButton arrowRightTurqBtn" >
                        <img src={ArrowRight} className="arrowRightTurquoise" alt="arrow right"/>
                    </button>
                </Col>
            </Row>
        </>
    )
}

export default FeaturedMobileCarousel