import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ServicesTabContent = () => {
    return(
        <>
            <div className="mobileTabContent">
                <Row>
                    <Col style={{padding: 0}}>
                        <div className="gallery">
                            <div className="gallery_scroller">
                                <div>
                                    <div className="clientsCard">
                                        <p>digital design experience</p>
                                        <h4 className="projectsList">EDUTOPIA</h4>
                                        <h4 className="projectsList">JUMIO</h4>
                                        <h4 className="projectsList">CSAA</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>launch + post-launch services</p>
                                        <h4 className="projectsList">AAA</h4>
                                        <h4 className="projectsList">HIROAD</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>product strategy</p>
                                        <h4 className="projectsList">VERANT ID</h4>
                                        <h4 className="projectsList">CRB Auto</h4>
                                        <h4 className="projectsList">SC VENTURES</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="clientsCard">
                                        <p>mobile & web development</p>
                                        <h4 className="projectsList">AYLA NETWORKS</h4>
                                        <h4 className="projectsList">BEST BUY</h4>
                                        <h4 className="projectsList">DIMPLEX</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="desktopTabContent servicesFont">
                <Row className="marginTen">
                    <Col>
                        <p style={{color: "#2DBC9D", fontSize: 40}} className="projectsBlurb projectsList">"QUICK TO LAUNCH, PROVEN TO SCALE"</p>
                    </Col>
                    <Col></Col>
                    <Col>
                        <p>digital design experience</p>
                        <h4 className="projectsList">EDUTOPIA</h4>
                        <h4 className="projectsList">JUMIO</h4>
                        <h4 className="projectsList">CSAA</h4>
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                        <p>launch + post-launch services</p>
                        <h4 className="projectsList">AAA</h4>
                        <h4 className="projectsList">HIROAD</h4>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <p>product strategy</p>
                        <h4 className="projectsList">VERANT ID</h4>
                        <h4 className="projectsList">CRB Auto</h4>
                        <h4 className="projectsList">SC VENTURES</h4>
                    </Col>
                    <Col></Col>
                    <Col>
                        <p>mobile & web development</p>
                        <h4 className="projectsList">AYLA NETWORKS</h4>
                        <h4 className="projectsList">BEST BUY</h4>
                        <h4 className="projectsList">DIMPLEX</h4>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
        </>
    )
}

export default ServicesTabContent;